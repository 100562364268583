<template>
    <b-modal ref="editMultiPointModal" size="lg" @hidden="closeModal">
        <!-- Modal Header -->
        <template #modal-header>
            <div class="d-flex justify-content-between align-items-center w-100">
                <h5 class="mb-0">관제점 정보 일괄 편집 (관리자용)</h5>
                <div class="d-flex justify-content-end" style="font-size: 14px">
                    <!-- <span class="setting-icon mr-2"> <i class="fas fa-check" @click="onConfirm"></i> </span> -->
                    <span class="setting-icon"> <i class="fas fa-times" @click="closeModal"></i> </span>
                </div>
            </div>
        </template>

        <!-- Modal Content -->
        <template #default>
            <div class="input-group mb-4">
                <span style="font-size:13px;">관제점 정보 일괄 편집 기능은 여러 관제점을 선택하여 특정 항목을 일괄 변경하는 기능을 제공합니다. 데이터베이스에 저장되는 정확한
                    값을 확인한 후 사용하십시요.</span>
            </div>

            <div class="row">
                <div class="input-group mb-2 col-md-6">
                    <div class="input-group-prepend">
                        <span for="selectProp" class="input-group-text custom-prepend">편집 항목 선택</span>
                    </div>
                    <select v-model="selectedProp" id="selectProp" class="form-control" @change="changeProp">
                        <option value="null">선택</option>
                        <option v-for="(item) in editableProps" :key="item.key" :value="item.key">
                            {{ `${item.text} (${item.key})` }}
                        </option>
                    </select>
                </div>

                <div class="input-group mb-2 col-md-6">
                    <div class="input-group-prepend">
                        <span for="inputValue" class="input-group-text custom-prepend">변경값 입력</span>
                    </div>
                    <input type="text" id="inputValue" class="form-control" v-model="newValue" />
                </div>
            </div>

            <div class="row">
                <vue-good-table ref="list-table" style="margin: 0.5rem 1rem 0.5rem 1rem; height:100%; width:100%;"
                    :columns="vgtColumns" :rows="pointList" compact-mode fixed-header>
                    <!-- Empty State Template -->
                    <template #emptystate>
                        <div style="text-align: center;">
                            {{ $t('표시할 데이터가 없습니다.') }}
                        </div>
                    </template>

                    <!-- Table Row Template -->
                    <!-- <template #table-row="props">
                        <div class="text-center">{{ props.column.field === 'ptAddr' ? props.row.ptAddr : '' }}</div>
                        <div class="text-center">{{ props.column.field === 'ptName' ? props.row.ptName : '' }}</div>
                        <div class="text-center">{{ props.column.field === 'currentValue' ? props.row.currentValue : ''
                            }}</div>
                        <div class="text-center">{{ props.column.field === 'newValue' ? props.row.newValue : '' }}</div>
                    </template> -->
                    <!-- 'props' 객체를 통해 각각의 열 데이터에 접근 -->

                    <!-- Table Row Template -->
                    <template #table-row="props">
                        <!-- 관제점 주소 -->
                        <div class="text-center">{{ props.column.field === 'ptAddr' ? props.row.ptAddr : '' }}</div>
                        <!-- 관제점명 -->
                        <div class="text-center">{{ props.column.field === 'ptName' ? props.row.ptName : '' }}</div>
                        <!-- 현재값 (파란색) -->
                        <div class="text-center" v-if="props.column.field === 'currentValue'" style="color: blue; font-weight:600;">
                            {{ props.row.currentValue }}
                        </div>
                        <!-- 변경후 (빨간색) -->
                        <div class="text-center" v-if="props.column.field === 'newValue'" style="color: red; font-weight: 600;">
                            {{ props.row.newValue }}
                        </div>
                    </template>
                </vue-good-table>
            </div>
        </template>

        <!-- Modal Footer -->
        <template #modal-footer>
            <div class="d-flex">
                <button type="button" class="btn btn-primary flex-fill mr-2" @click="onConfirm">저장</button>
                <button type="button" class="btn btn-secondary flex-fill" @click="closeModal">닫기</button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import backEndApi from '@src/api/backEndApi';

export default {
    props: {        
        ptIdxs: Array,              // 선택된 관제점 ID 목록
    },
    mounted() {
        this.setEditableProps();
        
        if(this.ptIdxs) this.loadData();
    },
    data() {
        return {
            editableProps: null,
            selectedProp: null,
            readonlyProps: ['ptIdx', 'ptAddr', 'ptGroup', 'pointAddress', 'ptVal'],
            newValue: '',
            pointList: null,
            vgtColumns: [
                {
                    label: '관제점 주소',
                    field: 'ptAddr',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    label: '관제점명',
                    field: 'ptName',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    label: '현재값',
                    field: 'currentValue',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
                {
                    label: '변경후',
                    field: 'newValue',
                    type: 'text',
                    thClass: 'text-center',
                    tdClass: 'text-center',
                },
            ],
        }
    },
    watch: {
        ptIdxs: {
            deep: true,
            async handler(newVal) {
                if(newVal) this.loadData();
            }
        },
        selectedProp() {
            const that = this;
            if (this.pointList) {
                this.pointList.forEach(item => {
                    // item.currentValue = item[that.selectedProp];
                    this.$set(item, 'currentValue', item[that.selectedProp]);
                });
            }
        },
        newValue() {
            const that = this;

            if (this.pointList) {
                this.pointList.forEach(item => {
                    // item.newValue = that.newValue;
                    this.$set(item, 'newValue', that.newValue);
                });
            }
        },
    },
    computed: { },
    methods: {
        setEditableProps() {
            // pointListOptions : 이름이 전역적으로 관리될 수 있도록 수정 필요
            //             관제점 정보 관리 항목 (필드명) 정도의 의미
            try {
                const pointListOptions = this.$store.state.pointListOptions;
    
                this.editableProps = pointListOptions.filter(prop => {
                    // prop.key : field name (ptIdx,...)
                    // prop.text : description.
                    let found = this.readonlyProps.find(name => name === prop.key);
                    return !found;
                });
    
            } catch (e) {
                console.error('setEditableProps error. ', e);
            }
        },
        onConfirm() {
            // 데이터를 저장하는 로직 추가
            this.saveData();
        },
        closeModal() {
            this.$emit('clear');
            this.$refs.editMultiPointModal.hide();
        },
        onClose() {
            this.$emit('hidden');
        },
        showModal() {
            this.$refs.editMultiPointModal.show();
        },
        changeProp(event) {
            this.selectedProp = event.target.value;
        },
        async loadData() {
            try {
                let result = await backEndApi.pointInfo.getPointInfoListById(this.ptIdxs);
                if (result.status === 200) {
                    this.pointList = result.data;

                    if (this.selectedProp) {
                        this.pointList.forEach(item => {
                            item.currentValue = item[this.selectedProp];
                            item.newValue = this.newValue ?? '';
                        });
                    }

                }
            } catch (e) {
                console.error(e);
            }
        },
        async saveData() {
            try {
                let pointInfos = null;

                pointInfos = this.pointList.map(item => ({ ptIdx: item.ptIdx, [this.selectedProp]: this.newValue }) );

                let result = await backEndApi.pointInfo.updatePointInfo(pointInfos);
                if (result.status === 200) {
                    await this.alertNoti("변경하였습니다.");
                    this.closeModal();
                }
            } catch (e) {
                console.error(e);
            }
        },
    }
}
</script>

<style scoped>
.input-group-text {
    text-align: center;
}

.input-group-text.custom-prepend {
    background-color: #348fe2;
    color: white;
    border: 1px solid #348fe2;
}
</style>
